import React, { useEffect, useState } from "react";
import { BiBuildingHouse } from "react-icons/bi";
import { AiOutlineMenuUnfold } from "react-icons/ai";
import { AiOutlineCloseCircle } from "react-icons/ai";

// import { data } from "./LinkData";
import { HashLink as Link } from "react-router-hash-link";
import "./styles/nav-style.css";
import { useGlobalContext } from "../Context/context";
import { FaAngleDown } from "react-icons/fa";
import GreeceIcon from "./images/greece.png";
import GreatBritainIcon from "./images/great-britain.png";
import GermanyIcon from "./images/germany.png";

import { useTranslation } from "react-i18next";

const Navigator = () => {
  const { t } = useTranslation();

  const {
    activeClass,
    positionServices,
    positionAboutUs,
    positionContactUs,
    setOpenMenuModal,
    openMenuModal,
  } = useGlobalContext();
  const data = [
    { id: 1, label: t("Navigation_Home"), linkUrl: "HomeActive" },
    { id: 2, label: t("Navigation_Services"), linkUrl: "ServicesActive" },
    { id: 3, label: t("Navigation_About_Us"), linkUrl: "AboutUsActive" },
    { id: 4, label: t("Navigation_Contact"), linkUrl: "ContactUsActive" },
    { id: 2, label: t("Navigation_Buy_House"), linkUrl: "buy-house" },
  ];

  const [selectedLanguage, setSelectedLanguage] = useState(GreatBritainIcon);
  const [showDropdown, setShowDropdown] = useState(false);

  const languages = [
    { select: "el", icon: GreeceIcon },
    { select: "en", icon: GreatBritainIcon },
    { select: "ger", icon: GermanyIcon },
  ];

  useEffect(() => {
    const savedLanguage = localStorage.getItem("appLanguage") || "en";
    if (savedLanguage === "el") {
      setSelectedLanguage(GreeceIcon);
    } else if (savedLanguage === "ger") {
      setSelectedLanguage(GermanyIcon);
    } else {
      setSelectedLanguage(GreatBritainIcon);
    }
  }, []);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const selectLanguage = (language) => {
    setSelectedLanguage(language.icon);
    setShowDropdown(false);
    localStorage.setItem("appLanguage", language.select);
    window.location.reload();
  };

  return (
    <>
      <nav className="navigator">
        <Link to="#" className="linkLogo">
          <div className="logo">
            <BiBuildingHouse className="logo-img" />
            My Greek House
          </div>
        </Link>

        <div className="menuButton" onClick={() => setOpenMenuModal(true)}>
          <AiOutlineMenuUnfold />
        </div>

        <nav
          className={
            openMenuModal
              ? "NavigatorSidebar activeNavigatorSidebar"
              : "NavigatorSidebar"
          }
        >
          <AiOutlineCloseCircle
            onClick={() => {
              setOpenMenuModal(false);
            }}
            className="FormalModalAiOutlineCloseCircle"
          />
          {data.map((item, index) => {
            const { id, label, linkUrl } = item;

            if (linkUrl !== "buy-house") {
              return (
                <article
                  key={index}
                  className={
                    activeClass === linkUrl
                      ? "nav-menu-choice active-nav-menu-choice"
                      : "nav-menu-choice"
                  }
                >
                  <Link
                    className="ServiceButtonMoreHP "
                    to={"/"}
                    onClick={() => {
                      if (linkUrl === "HomeActive") {
                        window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
                        setOpenMenuModal(false);
                      }
                      if (linkUrl === "ServicesActive") {
                        window.scrollTo({
                          top: positionServices - 100,
                          behavior: "smooth",
                        });
                        setOpenMenuModal(false);
                      }
                      if (linkUrl === "AboutUsActive") {
                        window.scrollTo({
                          top: positionAboutUs - 100,
                          behavior: "smooth",
                        });
                        setOpenMenuModal(false);
                      }
                      if (linkUrl === "ContactUsActive") {
                        window.scrollTo({
                          top: positionContactUs,
                          behavior: "smooth",
                        });
                        setOpenMenuModal(false);
                      }
                    }}
                  >
                    {label}
                  </Link>
                </article>
              );
            } else {
              return (
                <>
                  <article key={index} className="nav-menu-choice-login">
                    <Link
                      className="ServiceButtonMoreLogin"
                      to={"/buy-house"}
                      onClick={() => {
                        window.scrollTo(0, 0);
                        setOpenMenuModal(false);
                      }}
                    >
                      <button className="ServiceButtonMore"> {label} </button>
                    </Link>
                  </article>

                  <div className="language-selector language-selector-sidebar ">
                    <div className="selected-language" onClick={toggleDropdown}>
                      <img
                        src={selectedLanguage}
                        alt="Select language"
                        className="language-flag"
                      />
                      <FaAngleDown />
                    </div>
                    {showDropdown && (
                      <div className="language-options">
                        {languages.map((language, index) => (
                          <div
                            key={index}
                            className="language-option"
                            onClick={() => selectLanguage(language)}
                          >
                            <img
                              src={language.icon}
                              alt="Select language"
                              className="language-flag"
                            />
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </>
              );
            }
          })}
        </nav>

        <div className="nav-menu">
          {data.map((item, index) => {
            const { id, label, linkUrl } = item;

            if (linkUrl !== "buy-house") {
              return (
                <article
                  key={index}
                  className={
                    activeClass === linkUrl
                      ? "nav-menu-choice active-nav-menu-choice"
                      : "nav-menu-choice"
                  }
                >
                  <Link
                    className="ServiceButtonMoreHP "
                    to={"/"}
                    onClick={() => {
                      if (linkUrl === "HomeActive") {
                        window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
                      }
                      if (linkUrl === "ServicesActive") {
                        window.scrollTo({
                          top: positionServices - 100,
                          behavior: "smooth",
                        });
                      }
                      if (linkUrl === "AboutUsActive") {
                        window.scrollTo({
                          top: positionAboutUs - 100,
                          behavior: "smooth",
                        });
                      }
                      if (linkUrl === "ContactUsActive") {
                        window.scrollTo({
                          top: positionContactUs,
                          behavior: "smooth",
                        });
                      }
                    }}
                  >
                    {label}
                  </Link>
                </article>
              );
            } else {
              return (
                <>
                  <article key={index} className="nav-menu-choice-login">
                    <Link
                      className="ServiceButtonMoreLogin"
                      to={"/buy-house"}
                      onClick={() => {
                        window.scrollTo(0, 0);
                      }}
                    >
                      <button className="ServiceButtonMore"> {label} </button>
                    </Link>
                  </article>
                  <div className="language-selector">
                    <div className="selected-language" onClick={toggleDropdown}>
                      <img
                        src={selectedLanguage}
                        alt="Select language"
                        className="language-flag"
                      />
                      <FaAngleDown />
                    </div>
                    {showDropdown && (
                      <div className="language-options">
                        {languages.map((language, index) => (
                          <div
                            key={index}
                            className="language-option"
                            onClick={() => selectLanguage(language)}
                          >
                            <img
                              src={language.icon}
                              alt="Select language"
                              className="language-flag"
                            />
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </>
              );
            }
          })}
        </div>
      </nav>
    </>
  );
};

export default Navigator;
