import React, { useRef, useEffect, useState } from "react";
import tools from "./images/tools.jpg";
import toolsPlan from "./images/toolsPlan.jpg";
import { useGlobalContext } from "../Context/context";
import { useTranslation } from "react-i18next";

import "./styles/aboutusHP.css";
function AboutUsHP() {
  const { t } = useTranslation();

  const { setPositionAboutUs } = useGlobalContext();

  const [position, setPosition] = useState(0);
  const myRef = useRef();

  useEffect(() => {
    const y = myRef.current.offsetTop;
    setPosition(y);
  }, []);

  useEffect(() => {
    setPositionAboutUs(position);
    // eslint-disable-next-line
  }, [position]);

  return (
    <article className="aboutUsHp" ref={myRef}>
      <h1 className="aboutUsHpTitle">{t("BuilderSite_Title")}</h1>
      <section className="aboutUsHpTop">
        <img src={tools} alt="tools" className="aboutUsHpTopImg" />
        <div></div>

        <div>
          <h2>{t("BuilderSite_Subtitle")}</h2>
          <p>{t("BuilderSite_Paragraph_1")}</p>
        </div>
      </section>
      <section className="aboutUsHpBottom">
        <img src={toolsPlan} alt="tools" className="aboutUsHpBottomImg" />
        <div></div>
        <div>
          <p>{t("BuilderSite_Paragraph_2")}</p>
        </div>
      </section>
    </article>
  );
}

export default AboutUsHP;
