import React, { useState, useEffect, useContext } from 'react';
// import { useLocation } from "react-router-dom";

const AppContext = React.createContext();

const AppProvider = ({ children }) => {
  const [openModal, setOpenModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [positionServices, setPositionServices] = useState(0);
  const [positionAboutUs, setPositionAboutUs] = useState(0);
  const [positionContactUs, setPositionContactUs] = useState(0);
  const [activeClass, setActiveClass] = useState('HomeActive');
  const [homeSite, setHomeSite] = useState(true);
  const [openMenuModal, setOpenMenuModal] = useState(false);

  const resizeEvent = () => {
    if (window.innerWidth > 800) {
      setOpenMenuModal(false);
    }
    // console.log(window.innerWidth + ' ' + openMenuModal);
  };

  useEffect(() => {
    window.addEventListener('resize', resizeEvent);
    return () => window.removeEventListener('resize', resizeEvent);
  });

  const event = () => {
    const windowHeight = window.innerHeight;

    if (window.scrollY < positionServices - window.innerHeight / 2 + 100) {
      setActiveClass('HomeActive');
    } else if (
      window.scrollY >= positionServices - window.innerHeight / 2 + 100 &&
      window.scrollY < positionAboutUs - window.innerHeight / 2 + 100
    ) {
      setActiveClass('ServicesActive');
    } else if (
      window.scrollY >= positionAboutUs - window.innerHeight / 2 + 100 &&
      window.scrollY < positionContactUs - window.innerHeight / 2 + 100
    ) {
      setActiveClass('AboutUsActive');
    } else {
      setActiveClass('ContactUsActive');
    }
  };

  useEffect(() => {
    if (homeSite) {
      // setActiveClass("HomeActive");
      window.addEventListener('scroll', event);
      return () => window.removeEventListener('scroll', event);
    } else {
      setActiveClass('');
    }
  });

  return (
    <AppContext.Provider
      value={{
        openModal,
        activeClass,
        positionServices,
        positionAboutUs,
        positionContactUs,
        modalTitle,
        openMenuModal,
        setOpenModal,
        setPositionServices,
        setPositionAboutUs,
        setPositionContactUs,
        setModalTitle,
        setHomeSite,
        setActiveClass,
        setOpenMenuModal,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useGlobalContext = () => {
  return useContext(AppContext);
};

export { AppContext, AppProvider };
