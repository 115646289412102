import React, { useEffect } from 'react';
import Navigator from './Navigator';
import Intro from './Intro';
import ServicesHP from './ServicesHP';
import AboutUsHP from './AboutUsHP';
import TrustHP from './TrustHP';
import ReviewHP from './Reviews';
import ContactHP from './ContactHP';
import FormModal from '../FormModal/FormModal';
import { useGlobalContext } from '../Context/context';

function HomePage() {
  const { setHomeSite, setActiveClass } = useGlobalContext();
  useEffect(() => {
    setActiveClass('HomeActive');
    setHomeSite(true);
  }, []);
  return (
    <div>
      <Navigator />
      <Intro />
      <ServicesHP />
      <AboutUsHP />
      <TrustHP />
      <ReviewHP />
      <ContactHP />
      <FormModal />
    </div>
  );
}

export default HomePage;
