import React from "react";
import "./styles/InfoAboutProperty.css";
import { useTranslation } from "react-i18next";

function InfoAboutProperty() {
  const { t } = useTranslation();

  return (
    <article className="InfoAboutProperty">
      <h1 className="InfoAboutPropertyTitle">{t("BuyHouse_Info_Title")} </h1>
      <section className="InfoAboutPropertyInfo">
        <p>
          &nbsp; &nbsp; {t("BuyHouse_Info_Paragraoh_1")} <br />
          &nbsp; &nbsp; {t("BuyHouse_Info_Paragraoh_2")} <br />
          &nbsp; &nbsp; {t("BuyHouse_Info_Paragraoh_3")}
        </p>
      </section>
      <section className="InfoAboutPropertyCharacteristic">
        <h1>{t("BuyHouse_Info_Discriptions_Title")} </h1>
        <div>
          <h5>{t("BuyHouse_Info_Discriptions_Price")} </h5>
          <h5>€{t("BuyHouse_Info_Discriptions_Price_Ans")} </h5>
        </div>
        <div>
          <h5>{t("BuyHouse_Info_Discriptions_Price_m_2")} </h5>
          <h5>€{t("BuyHouse_Info_Discriptions_Price_m_2_ans")} </h5>
        </div>
        <div>
          <h5>{t("BuyHouse_Info_Discriptions_Area")} </h5>
          <h5>{t("BuyHouse_Info_Discriptions_Area_ans")} </h5>
        </div>
        <div>
          <h5>{t("BuyHouse_Info_Discriptions_Place_Area")} </h5>
          <h5>{t("BuyHouse_Info_Discriptions_Place_Area_ans")} </h5>
        </div>
        <div>
          <h5>{t("BuyHouse_Info_Discriptions_Levels")} </h5>
          <h5>{t("BuyHouse_Info_Discriptions_Levels_ans")} </h5>
        </div>
        <div>
          <h5>{t("BuyHouse_Info_Discriptions_Kichens")} </h5>
          <h5>{t("BuyHouse_Info_Discriptions_Kichens_ans")} </h5>
        </div>
        <div>
          <h5>{t("BuyHouse_Info_Discriptions_Bath")} </h5>
          <h5>{t("BuyHouse_Info_Discriptions_Bath_ans")} </h5>
        </div>
        <div>
          <h5>{t("BuyHouse_Info_Discriptions_Bath_WC")} </h5>
          <h5>{t("BuyHouse_Info_Discriptions_Bath_WC_ans")} </h5>
        </div>
        <div>
          <h5>{t("BuyHouse_Info_Discriptions_Living_Room")} </h5>
          <h5>{t("BuyHouse_Info_Discriptions_Living_Room_ans")} </h5>
        </div>
        <div>
          <h5>{t("BuyHouse_Info_Discriptions_Agency")} </h5>
          <h5>{t("BuyHouse_Info_Discriptions_Agebcy_ans")}</h5>
        </div>
        <div>
          <h5>{t("BuyHouse_Info_Discriptions_Heating")} </h5>
          <h5 className="HeatingSystem">
            {t("BuyHouse_Info_Discriptions_Heating_ans")}{" "}
          </h5>
        </div>
        <div>
          <h5>{t("BuyHouse_Info_Discriptions_Year")} </h5>
          <h5>{t("BuyHouse_Info_Discriptions_Year_ans")} </h5>
        </div>
      </section>
    </article>
  );
}

export default InfoAboutProperty;
