import React from "react";
import "./styles/trustsHP.css";

//images
import experience from "./images/experience.png";
import medal from "./images/medal.png";
import shield from "./images/shield.png";

import { useTranslation } from "react-i18next";

function TrustHP() {
  const { t } = useTranslation();

  return (
    <article className="TrustsHP">
      <h1 className="TrustsHPtitle">{t("BuilderSite_Trust_Title")} </h1>
      <section className="TrustsHPboxes">
        <div className="TrustItem">
          <img src={experience} alt="experience" />
          <h3>{t("BuilderSite_Trust_1")}</h3>
          <p>{t("BuilderSite_Trust_1_Paragraph")}</p>
        </div>
        <div className="TrustItem">
          <img src={medal} alt="medal" />
          <h3>{t("BuilderSite_Trust_2")} </h3>
          <p>{t("BuilderSite_Trust_2_Paragraph")}</p>
        </div>
        <div className="TrustItem">
          <img src={shield} alt="shield" />
          <h3>{t("BuilderSite_Trust_3")} </h3>
          <p>{t("BuilderSite_Trust_3_Paragraph")}</p>
        </div>
      </section>
    </article>
  );
}

export default TrustHP;
