import React from "react";
import "./styles/reviews.css";

//images
import review from "./images/quotation-mark.png";

import { useTranslation } from "react-i18next";

function ReviewHP() {
  const { t } = useTranslation();

  return (
    <article className="ReviewHP">
      <h1 className="ReviewHPtitle"> {t("BuilderSite_Reviews_Title")} </h1>
      <section className="ReviewHPboxes">
        <div className="ReviewItem">
          <img src={review} alt="experience" />
          <p>“{t("BuilderSite_Reviews_1")}”</p>
          <h3>{t("BuilderSite_Reviews_1_Name")}</h3>
        </div>
        <div className="ReviewItem">
          <img src={review} alt="medal" />
          <p>“{t("BuilderSite_Reviews_2")}”</p>
          <h3>{t("BuilderSite_Reviews_2_Name")}</h3>
        </div>
        <div className="ReviewItem">
          <img src={review} alt="shield" />
          <p>“{t("BuilderSite_Reviews_3")}”</p>
          <h3> {t("BuilderSite_Reviews_3_Name")}</h3>
        </div>
      </section>
    </article>
  );
}

export default ReviewHP;
