import "./App.css";
import HomePage from "./builderSite/HomePage";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import BuyHouse from "./BuyHouseSite/BuyHouseHome";
import ErrorPage from "./ErrorPage";
function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/buy-house" element={<BuyHouse />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
