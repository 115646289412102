import React, { useEffect } from 'react';
import Intro from '../BuyHouseSite/Intro';
import Navigator from '../builderSite/Navigator';
import { useGlobalContext } from '../Context/context';
import InfoAboutProperty from './InfoAboutProperty';
import ContactHP from '../builderSite/ContactHP';
function BuyHouseHome() {
  const { setHomeSite } = useGlobalContext();
  useEffect(() => {
    setHomeSite(false);
  }, []);
  return (
    <article>
      <Navigator />
      <Intro />
      <InfoAboutProperty />
      <ContactHP />
    </article>
  );
}

export default BuyHouseHome;
