import React, { useRef, useEffect, useState } from "react";
import "./styles/contactHP.css";
import { BsTelephone } from "react-icons/bs";
import { useGlobalContext } from "../Context/context";
import axios from "axios";
import LoadingDots from "../LoadingDots/LoadingDots";

import { useTranslation } from "react-i18next";

function ContactHP() {
  const { t } = useTranslation();

  const { setPositionContactUs } = useGlobalContext();
  const [errorMessage, setErrorMessage] = useState(false);
  const [sendPressed, setSendPress] = useState(false);

  const [position, setPosition] = useState(0);

  const myRef = useRef();

  useEffect(() => {
    const y = myRef.current.offsetTop;
    setPosition(y);
  }, []);

  useEffect(() => {
    setPositionContactUs(position);
    // eslint-disable-next-line
  }, [position]);

  const [person, setPerson] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  function handleChange(e) {
    setErrorMessage(false);
    const name = e.target.name;
    const value = e.target.value;
    setPerson({ ...person, [name]: value });
  }

  const handleSubmit = async (e) => {
    setSendPress(true);

    const name = person.name;
    const email = person.email;
    const phone = person.phone;
    const message = person.message;
    setErrorMessage(false);

    if (!name || !email || !phone || !message) {
      e.preventDefault();
      setErrorMessage(true);
      setSendPress(false);
      return;
    }
  };

  return (
    <article className="contactHP" ref={myRef}>
      <h2 className="contactHPtitle">{t("BuilderSite_ContactUs_Title")}</h2>
      <div className="contactHPdiv">
        <section className="contactMap">
          <h2>{t("BuilderSite_ContactUs_Address_Title")}</h2>
          <h3>{t("BuilderSite_ContactUs_Address_Paragraph")}</h3>
          <div className="mapouter">
            <div className="gmap_canvas">
              <iframe
                title="Map title"
                className="gmap_canvasStyle"
                // width='600'
                // height='500'
                id="gmap_canvas"
                src="https://maps.google.com/maps?q=%20Kiparissia%20245%2000,%20Greece&t=&z=13&ie=UTF8&iwloc=&output=embed"
                frameBorder="0"
                scrolling="no"
                marginHeight="0"
                marginWidth="0"
              ></iframe>
              <br />
            </div>
          </div>
        </section>
        <section className="contactForm">
          <h2>{t("BuilderSite_ContactUs_Subtitle")}</h2>

          <form
            className="contactFormIn"
            method="POST"
            name="My_Greek_Home"
            data-netlify="true"
            onSubmit="submit"
          >
            <input type="hidden" name="form-name" value="My_Greek_Home" />
            <div hidden>
              <label>Name:</label>
              <input type="text" name="form_name" value={person.name} />
            </div>
            <div hidden>
              <label>Email:</label>
              <input type="text" name="form_email" value={person.email} />
            </div>
            <div hidden>
              <label>Phone:</label>
              <input type="text" name="form_phone" value={person.phone} />
            </div>
            <div hidden>
              <label>Message:</label>
              <textarea name="form_message" value={person.message} />
            </div>

            <input
              type="text"
              id="ContactName"
              name="name"
              value={person.name}
              onChange={handleChange}
              placeholder={t("BuilderSite_ContactUs_Form_Name")}
              required
            />
            <input
              type="email"
              id="ContactEmail"
              name="email"
              value={person.email}
              onChange={handleChange}
              placeholder={t("BuilderSite_ContactUs_Form_Email")}
              required
            />
            <input
              type="text"
              id="ContactPhone"
              name="phone"
              value={person.phone}
              onChange={handleChange}
              placeholder={t("BuilderSite_ContactUs_Form_Phone")}
              required
            />
            <textarea
              id="ContactMessage"
              name="message"
              value={person.message}
              onChange={handleChange}
              placeholder={t("BuilderSite_ContactUs_Form_Message")}
              required
            />
            <button onClick={handleSubmit}>
              {sendPressed ? (
                <LoadingDots />
              ) : (
                t("BuilderSite_ContactUs_Form_Send_Btn")
              )}{" "}
            </button>
            <h5
              className={
                errorMessage ? "errorMessage openErrorMessage" : "errorMessage"
              }
            >
              {t("BuilderSite_ContactUs_Form_Error_Msg")}
            </h5>
            <h2 className="phoneTitle">
              {t("BuilderSite_ContactUs_Form_Phone_Choice")}
            </h2>
            <div className="contactCall">
              <BsTelephone className="BsTelephone" />
              Call us:
              <a
                href="tel:+306946509537"
                target="_blank"
                style={{ color: "white", textDecoration: "none" }}
              >
                +30 6946509537
              </a>
            </div>
            <div className="contactCall">
              <BsTelephone className="BsTelephone" />
              Call us:
              <a
                href="tel:+4915906133318"
                target="_blank"
                style={{ color: "white", textDecoration: "none" }}
              >
                +49 15906133318
              </a>
            </div>
          </form>
        </section>
      </div>
    </article>
  );
}

export default ContactHP;
