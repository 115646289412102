import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Importing the translation files
import translationEL from "./locales/el/translation.json";
import translationEN from "./locales/en/translation.json";
import translationGER from "./locales/ger/translation.json";
// ... other language imports

// Retrieve language code from local storage or default to 'en'
const savedLanguage = localStorage.getItem("appLanguage") || "en";

// Setting up the i18next configuration
i18n
  .use(initReactI18next) // Passes i18n down to react-i18next
  .init({
    resources: {
      en: { translation: translationEN },
      ger: { translation: translationGER },
      el: { translation: translationEL },
      // sq: { translation: translationSQ },
      // ... other languages
    },
    lng: savedLanguage, // Use saved language as default
    fallbackLng: "en", // Fallback language
    keySeparator: false, // To support nested translations

    interpolation: {
      escapeValue: false, // React already safes from XSS
    },
  });

export default i18n;
