import React, { useState, useEffect } from "react";
import {
  MdOutlineArrowBackIos,
  MdOutlineArrowForwardIos,
} from "react-icons/md";

import { BsTelephone } from "react-icons/bs";

import "./styles/intro.css";
import { useGlobalContext } from "../Context/context";

//images
//main-house
//--main-house
import data from "./imageDataBuy";
//--images

import { useTranslation } from "react-i18next";

function Intro() {
  const { t } = useTranslation();

  const { positionContactUs } = useGlobalContext();

  // eslint-disable-next-line
  const [introImages, setIntroImages] = useState(data);
  const [index, setIndex] = React.useState(0);

  const nextSlides = () => {
    setIndex((oldIndex) => {
      let index = oldIndex + 1;
      if (index > introImages.length - 1) {
        index = 0;
      }
      return index;
    });
  };
  const prevSlide = () => {
    setIndex((oldIndex) => {
      let index = oldIndex - 1;
      if (index < 0) {
        index = introImages.length - 1;
      }
      return index;
    });
  };

  useEffect(() => {
    let slider = setInterval(() => {
      setIndex((oldIndex) => {
        let index = oldIndex + 1;
        if (index > introImages.length - 1) {
          index = 0;
        }
        return index;
      });
    }, 5000);
    return () => {
      clearInterval(slider);
    };
    // eslint-disable-next-line
  }, [index]);

  return (
    <article className="HomePageIntroBuy">
      <section className="imagesSectionBuy">
        <MdOutlineArrowBackIos
          className="MdOutlineArrowBackIosBuy"
          onClick={prevSlide}
        />
        {introImages.map((imageDt, imageDtIndex) => {
          const { id, image } = imageDt;

          let position = "nextSlideBuy";
          if (imageDtIndex === index) {
            position = "activeSlideBuy";
          }
          if (
            imageDtIndex === index - 1 ||
            (index === 0 && imageDtIndex === introImages.length - 1)
          ) {
            position = "lastSlideBuy";
          }

          return (
            <article key={id} className={`introImagesBuy ${position}`}>
              <img
                src={require(`../builderSite/images/${image}`)}
                alt="construction house"
                className={`introImagesInsBuy`}
              />
            </article>
          );
        })}

        <div className="introImagesDivBuy"></div>
        <MdOutlineArrowForwardIos
          className="MdOutlineArrowForwardIosBuy"
          onClick={nextSlides}
        />
      </section>
      <div className="introCall">
        <BsTelephone className="BsTelephoneBuy" />
        Call us:
        <a
          href="tel:+306946509537"
          target="_blank"
          style={{ color: "white", textDecoration: "none" }}
        >
          +30 6946509537
        </a>
      </div>
      <div className="introCallGerm">
        <BsTelephone className="BsTelephone" />
        Call us:
        <a
          href="tel:+4915906133318"
          target="_blank"
          style={{ color: "white", textDecoration: "none" }}
        >
          +49 15906133318
        </a>
      </div>
      <div className="introParBuy">
        <h1>My Greek House</h1>
        <h3>€200.000</h3>
        <p>{t("BuyHouse_Intro_Title")}</p>
        <div>----------------</div>
        <h5>Θες να μάθεις περισσότερα?</h5>
        <button
          style={{ cursor: "pointer" }}
          onClick={() => {
            window.scrollTo({
              top: positionContactUs,
              behavior: "smooth",
            });
          }}
        >
          Επικοινώνησε μαζί μας
        </button>
      </div>
    </article>
  );
}

export default Intro;
