const data = [
  { id: 1, image: 'front_house.jpg' },
  { id: 2, image: 'house_construction_2.jpg' },
  { id: 3, image: 'house_construction_3.jpg' },
  { id: 4, image: 'house_construction_4.jpg' },
  { id: 5, image: 'house_construction_5.jpg' },
  { id: 6, image: 'house_construction_6.jpg' },
  { id: 7, image: 'whole_house.jpg' },
  { id: 8, image: 'house_construction_8.jpg' },
  { id: 9, image: 'house_construction_9.jpg' },
  { id: 10, image: 'house_construction_10.jpg' },
  { id: 11, image: 'house_construction_11.jpg' },
  { id: 12, image: 'house_construction_7.jpg' },
];

export default data;
