import React, { useRef, useEffect, useState } from "react";
import "./styles/servicesHP.css";
import { useGlobalContext } from "../Context/context";

//images
import outsideHouseServ from "./images/outsideHouseServ.jpg";
import insideHouseServ from "./images/insideHouseServ.jpg";
import houseRenovationServ from "./images/houseRenovationServ.jpg";

import { useTranslation } from "react-i18next";

function ServicesHP() {
  const { t } = useTranslation();

  const { setPositionServices, setOpenModal, setModalTitle } =
    useGlobalContext();

  const [position, setPosition] = useState(0);
  const myRef = useRef();

  useEffect(() => {
    const y = myRef.current.offsetTop;
    setPosition(y);
  }, []);

  useEffect(() => {
    setPositionServices(position);
    // eslint-disable-next-line
  }, [position]);

  return (
    <article className="ServicesHP" ref={myRef}>
      <h1 className="ServicesHPtitle">{t("BuilderSite_Services_Title")} </h1>
      <section className="ServicesHPboxes">
        <div className="serviceItem">
          <img src={outsideHouseServ} alt="outside House" />
          <h3>{t("BuilderSite_Services_1_Title")} </h3>
          <p>
            {t("BuilderSite_Services_1_Paragraph")}

            <br />
            <span
              style={{
                textDecoration: "underline",
                fontStyle: "italic",
              }}
            >
              {t("BuilderSite_Services_Press_Btn")}
            </span>
          </p>
          <button
            className="serviceMoreButton"
            onClick={() => {
              setModalTitle(t("BuilderSite_Services_1_Title"));
              setOpenModal(true);
            }}
          >
            {t("BuilderSite_Services_Press_Btn_Learn_More")}
          </button>
        </div>
        <div className="serviceItem">
          <img src={insideHouseServ} alt="inside House" />
          <h3>{t("BuilderSite_Services_2_Title")}</h3>
          <p>
            {t("BuilderSite_Services_2_Paragraph")}

            <br />
            <span
              style={{
                textDecoration: "underline",
                fontStyle: "italic",
              }}
            >
              {t("BuilderSite_Services_Press_Btn")}
            </span>
          </p>
          <button
            className="serviceMoreButton"
            onClick={() => {
              setModalTitle(t("BuilderSite_Services_2_Title"));
              setOpenModal(true);
            }}
          >
            {t("BuilderSite_Services_Press_Btn_Learn_More")}
          </button>
        </div>
        <div className="serviceItem">
          <img src={houseRenovationServ} alt="Renovation House" />
          <h3>{t("BuilderSite_Services_3_Title")} </h3>
          <p>
            {t("BuilderSite_Services_3_Paragraph")}

            <br />
            <span
              style={{
                textDecoration: "underline",
                fontStyle: "italic",
              }}
            >
              {t("BuilderSite_Services_Press_Btn")}
            </span>
          </p>
          <button
            className="serviceMoreButton"
            onClick={() => {
              setModalTitle(t("BuilderSite_Services_3_Title"));
              setOpenModal(true);
            }}
          >
            {t("BuilderSite_Services_Press_Btn_Learn_More")}
          </button>
        </div>
      </section>
    </article>
  );
}

export default ServicesHP;
